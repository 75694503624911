'use client';

import { useState } from 'react';
import { FileRejection, FileWithPath } from 'react-dropzone';
import { uploadToStorage } from '../helpers/upload-to-storage';

type UpsertContentResult = {
  [key: string]: {
    id: string;
  };
};

type FulfilledResult = PromiseFulfilledResult<UpsertContentResult>;

interface UseFileUploadProps<TOwnerType> {
  onUploadStart?: () => void;
  setError: (error: string) => void;
  ownerType: TOwnerType;
  sourceOwnerType?: TOwnerType;
  chatOwnerType?: TOwnerType;
  chatId?: string;
  // FIXME: Type THIS
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  upsertContent: any;
  upsertContentAttributeName?: string;
  scopeId?: string;
  storeInternally?: boolean;
  assistantIngestionConfig?: unknown;
  maxFiles: number;
  maxFileSize: number;
}

export interface HandleUploadOptions {
  newChatId?: string;
  scopeId?: string;
}

export interface FileForUpload {
  file: FileWithPath;
  fileName: string;
  progress: number;
  error?: string;
  uploadedAt: number;
  scopeId?: string;
  contentId?: string;
}

/**
 * Hook to handle multiple file uploads.
 * @param {function} setFilter - function to set the filter variables of the content query. used to reset the filter
 * @param {function} setError - function to set global error message
 */
export const useFileUpload = <TOwnerType,>({
  onUploadStart,
  setError,
  ownerType,
  chatOwnerType,
  chatId,
  sourceOwnerType,
  upsertContent,
  upsertContentAttributeName = 'contentUpsert',
  scopeId,
  storeInternally,
  assistantIngestionConfig,
  maxFiles,
  maxFileSize,
}: UseFileUploadProps<TOwnerType>) => {
  const [files, setFiles] = useState<FileForUpload[]>([]);

  const formatToReadableDate = (date: Date) => {
    const pad = (num: number) => num.toString().padStart(2, '0');

    return `${date.getFullYear()}-${pad(date.getMonth() + 1)}-${pad(date.getDate())}_${pad(date.getHours())}:${pad(date.getMinutes())}`;
  };

  /**
   * @param {string} newChatId - If the user uploads knowledge before the
   *    first chat message, a new chat has to be initialized and the chatId
   *    that is passed to the hook is still undefined. The new chatId is
   *    passed to this upload function.
   */
  const handleUpload = async (
    acceptedFiles: FileWithPath[],
    fileRejections: FileRejection[],
    // newChatId?: string,
    options?: HandleUploadOptions,
  ) => {
    onUploadStart?.();

    if (
      fileRejections.some((file) => file.errors.some((error) => error.code === 'too-many-files'))
    ) {
      setError(`Too many files. You can upload a maximum of ${maxFiles} files at once.`);
      return;
    }

    const mappedAcceptedFiles = acceptedFiles.map((file) => ({
      file,
      fileName: file.name,
      progress: 0,
      uploadedAt: new Date().getTime(),
    }));

    const mappedRejectedFiles = fileRejections.map((file) => {
      const mainError = file.errors[0];
      return {
        file: file.file,
        fileName: file.file.name,
        progress: 100,
        uploadedAt: new Date().getTime(),
        error:
          mainError.code === 'file-invalid-type'
            ? 'Invalid File Type'
            : mainError.code === 'file-too-large'
              ? `File too large. The current limit is ${maxFileSize / 1024 / 1024} MB per file.`
              : 'Unknown Error',
      };
    });

    // !The ORDER is important! We rely on the index of the files to stay the same
    // Accepted files must come first because their error and progress state is updated based on the index later on
    // Also include existing files because we want to keep showing files that errored out during previous uploads
    setFiles((prevFiles) => [...mappedAcceptedFiles, ...mappedRejectedFiles, ...prevFiles]);

    let contentIds: string[] = [];
    try {
      // Step 1: We create content records in order to get a writeUrl of a Storage
      const contentResult = await Promise.allSettled(
        mappedAcceptedFiles.map(({ file }) => {
          if (!file.name) throw new Error('File name is missing');
          if (!file.type) throw new Error('File type is missing');

          const uniqueKeyForFileUpload = `Chat_${formatToReadableDate(new Date())}_${file.name}`;

          return upsertContent(
            {
              input: {
                key: chatId || options?.newChatId ? uniqueKeyForFileUpload : file.name,
                mimeType: file.type,
                ownerType: chatId || options?.newChatId ? chatOwnerType : ownerType,
              },
              sourceOwnerType,
              ...((chatId || options?.newChatId) && {
                chatId: options?.newChatId ? options?.newChatId : chatId,
              }),
              ...((scopeId || options?.scopeId) && {
                scopeId: options?.scopeId ? options?.scopeId : scopeId,
              }),
              ...(storeInternally && { storeInternally }),
            },
            { revalidate: false },
          );
        }),
      );

      setErrorsFromResults(contentResult, 'Creating the upload record failed');

      // Step 2: We upload the files to a Storage
      const uploadResults = await uploadToStorage({
        upsertContentAttributeName,
        content: contentResult.map((r) => r.status === 'fulfilled' && r.value),
        files: mappedAcceptedFiles.map((f) => f.file),
        setFileProgress,
      });

      setErrorsFromResults(uploadResults, 'Upload to storage failed');

      // Step 3: We update the content records with the readUrl, so ingestion can start.
      const updateResult = await Promise.allSettled(
        uploadResults.map((result) => {
          if (result.status === 'rejected') return result;

          return upsertContent(
            {
              input: {
                key: result.value.content[upsertContentAttributeName].key,
                mimeType: result.value.content[upsertContentAttributeName].mimeType,
                ownerType: result.value.content[upsertContentAttributeName].ownerType,
                byteSize: result.value.byteSize,
                ...(assistantIngestionConfig ? { ingestionConfig: assistantIngestionConfig } : {}),
              },
              scopeId:
                result.value.content[upsertContentAttributeName].ownerType === 'SCOPE'
                  ? result.value.content[upsertContentAttributeName].ownerId
                  : undefined,
              sourceOwnerType,
              fileUrl: result.value.content[upsertContentAttributeName].readUrl,
              ...((chatId || options?.newChatId) && {
                chatId: options?.newChatId ? options?.newChatId : chatId,
              }),
              ...(storeInternally && { storeInternally }),
            },
            { revalidate: true },
          );
        }),
      );

      contentIds = updateResult
        .filter((result): result is FulfilledResult => result.status === 'fulfilled')
        .filter((result) => !!result.value)
        .map((result) => result.value[upsertContentAttributeName]?.id);

      setErrorsFromResults(updateResult, 'Updating the upload record failed');
    } catch (e) {
      console.log(e);
      setError('Upload failed');
    } finally {
      // Remove all successfully uploaded files from the state after 1000ms
      // Keep files with errors so that user knows which uploads failed
      setTimeout(
        () => setFiles((prevFiles) => prevFiles.filter((file: FileForUpload) => file?.error)),
        1000,
      );
    }
    return contentIds;
  };

  function setErrorsFromResults<T>(results: PromiseSettledResult<T>[], error: string) {
    results.forEach((result, index) => {
      if (result.status === 'rejected') {
        setFiles((prevFiles) =>
          prevFiles.map((file, i) => {
            if (i === index) {
              return {
                ...file,
                error: error,
                progress: 100,
              };
            }
            return file;
          }),
        );
      }
    });
  }

  const setFileProgress = (fileName: string, progress: number) => {
    setFiles((prevFiles) =>
      prevFiles.map((file) => {
        if (file.fileName === fileName) {
          return {
            ...file,
            progress,
          };
        }
        return file;
      }),
    );
  };

  return {
    files,
    handleUpload,
  };
};
