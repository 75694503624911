import { FILE_TYPES, FileType } from '../constants/file-type';

export const getFileTypeByMimeType = (mimeType: string) => {
  return Object.keys(FILE_TYPES).find((fileType) => {
    return FILE_TYPES[fileType as FileType].includes(mimeType);
  });
};

export const getTruncatedString = (text: string, MAX_THRESHOLD = 20) => {
  if (text.length <= MAX_THRESHOLD) return text;
  else
    return `${text.substring(0, MAX_THRESHOLD / 2)}...${text.substring(
      text.length - MAX_THRESHOLD / 2,
    )}`;
};
