const escapeDollarSigns = (text: string) => {
  if (!text) return '';
  return text.replace(/\$/g, '\\$');
};

/** Function to detect LaTeX formulas and wrap them in dollar signs for rendering with remark-math */
export const wrapLatexInDollars = (text: string) => {
  if (!text) return '';

  // Regex for classic LaTeX inline math: \( ... \)
  const inlineMathRegex = /\\\((.+?)\\\)/g;
  // Regex for classic LaTeX block math: \[ ... \]
  const blockMathRegex = /\\\[(.+?)\\\]/g;

  // Replace inline math
  text = text.replace(inlineMathRegex, (_, math) => `$${math}$`);

  // Replace block math
  text = text.replace(blockMathRegex, (_, math) => `$$${math}$$`);

  return text;
};

export const applyLatexFormat = (content: string) => wrapLatexInDollars(escapeDollarSigns(content));
