'use client';
import { useEffect } from 'react';

/**
 * This redirects the user to the base path if they are not already there.
 * Was created as a hotfix for UN-9081, but might stay for localhost development now
 * redirecting localhost:3003 to localhost:3003/${basePath}
 * @param basePath The base path to redirect to
 * @returns 
 */
export const useRedirectToBasePath = (basePath: string) => {
  useEffect(() => {
    if (basePath && !window.location.pathname.startsWith(basePath)) {
      window.location.pathname = basePath;
    }
  }, [basePath]);
};