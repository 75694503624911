'use client';
import { IconHistory } from '@unique/icons';
import {
  CellContextMenuEvent,
  CellEditingStartedEvent,
  CellEditingStoppedEvent,
  CellEditRequestEvent,
  ColDef,
  GetContextMenuItems,
  GetRowIdParams,
  GridReadyEvent,
  LicenseManager,
  MenuModule,
  ModuleRegistry,
  RowClassParams,
  RowHeightParams,
  RowSelectedEvent,
  SideBarDef,
  SizeColumnsToContentStrategy,
  SizeColumnsToFitGridStrategy,
  SizeColumnsToFitProvidedWidthStrategy,
} from 'ag-grid-enterprise';
import 'ag-grid-enterprise/styles/ag-grid.css'; // Mandatory CSS required by the Data Grid
import 'ag-grid-enterprise/styles/ag-theme-quartz.css'; // Optional Theme applied to the Data Grid
import { AgGridReact, AgGridReactProps, CustomCellRendererProps } from 'ag-grid-react';
import lodash from 'lodash';
import {
  forwardRef,
  memo,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { renderToString } from 'react-dom/server';
import './styles/ag-theme-unique.css'; // Custom Theme applied to the Data Grid
import { calculateTextHeight, idColumn, TOTAL_COLUMNS } from './utils/helpers';

// it's a trial license key, hence it's safe to be here. for the production key we would add to github secret and add to the build process. Sorry Michael, I know you don't like this but it's a trial key.
LicenseManager.setLicenseKey(
  '[TRIAL]_this_{AG_Charts_and_AG_Grid}_Enterprise_key_{AG-069969}_is_granted_for_evaluation_only___Use_in_production_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_purchasing_a_production_key_please_contact_info@ag-grid.com___You_are_granted_a_{Single_Application}_Developer_License_for_one_application_only___All_Front-End_JavaScript_developers_working_on_the_application_would_need_to_be_licensed___This_key_will_deactivate_on_{14 December 2024}____[v3]_[0102]_MTczNDEzNDQwMDAwMA==780fd123a2c611cd03659bed35285e94',
);
ModuleRegistry.registerModules([MenuModule]);

export type CellEditing = {
  value: string | number;
  columnId: string;
  rowId: number | null;
};

export type CustomMagicTableRow = { [x: string]: string };

export interface MagicTableResult {
  position: number;
  rowData?: {
    columnId: string;
    value: unknown;
  }[];
}

interface MagicTableProps {
  onRowSelected?: (selected: unknown) => void;
  cellEditingStart?: (event: CellEditing) => void;
  cellEditingEnd?: (event: CellEditing) => void;
  className?: string;
  handleCellEditRequest: ({ rowIndex, columnId, newValue, event }: CellEditRequest) => void;
  contextMenuItems?: GetContextMenuItems;
  loading?: boolean;
  onGridReady?: (event: GridReadyEvent) => void;
  sidebar?: SideBarDef;
  onCellContextMenu?: (event: CellContextMenuEvent) => void;
  CustomCellRenderer?: (props: CustomCellRendererProps) => JSX.Element;
}
export interface MagicTableRefHandles {
  updateColumnDefs: (newColDefs: ColDef[]) => void;
  updateRowData: (newRowData: CustomMagicTableRow[]) => void;
  getTableData: () => MagicTableResult[];
  exportAsCSV: (fileName: string) => void;
  stopEditingCell: () => void;
  updateCellValues: (rowNode: number, columnNode: string, newValue: string) => void;
  getLastOccupiedColumn: () => number;
  getColumnIndex: (columnId: string) => number;
  getColumnAtIndex: (index: number) => string;
  setRowUpdate: (rowIndex: number, data: CustomMagicTableRow[]) => void;
  getLastOccupiedRow: () => number;
  setSideBarVisible: (value?: boolean) => void;
  setSideBar: (def: SideBarDef | string | string[] | boolean) => void;
  openToolPanel: (id: string) => void;
}

export interface CellEditRequest {
  rowIndex: number;
  columnId: string;
  newValue: string;
  event: CellEditRequestEvent;
}

const defaultColDef: ColDef<unknown> = {
  flex: 1,
  sortable: false,
  minWidth: 200,
  editable: true,
  wrapText: true,
  autoHeight: false,
  cellClass: 'custom-ag-cell',
  context: false,
  suppressHeaderMenuButton: true,
  suppressHeaderFilterButton: true,
  suppressHeaderContextMenu: true,
  enableCellChangeFlash: true,
  cellEditor: 'agLargeTextCellEditor',
  cellEditorPopup: false,
  cellEditorParams: {
    rows: 100,
    cols: 50,
  },
  cellRenderer: 'customCellRenderer',
};

const Table = forwardRef<MagicTableRefHandles, MagicTableProps & AgGridReactProps<unknown>>(
  (props, ref) => {
    const {
      onRowSelected,
      cellEditingStart,
      cellEditingEnd,
      className = 'h-[73vh]',
      handleCellEditRequest,
      contextMenuItems,
      loading,
      onGridReady,
      sidebar,
      onCellContextMenu,
      CustomCellRenderer,
    } = props;
    const [rowData, setRowData] = useState<unknown[]>([]);

    const agGridRef = useRef<AgGridReact>(null);

    const [colDefs, setColDefs] = useState<ColDef<unknown>[]>([]);

    const updateColumnDefs = (newColDefs: ColDef[]) => {
      setColDefs((prevColDefs) => {
        if (lodash.isEqual(prevColDefs, newColDefs)) {
          return prevColDefs;
        }
        return newColDefs;
      });
    };

    const updateRowData = (newRowData: CustomMagicTableRow[]) => {
      setRowData((prevRowData) => {
        if (lodash.isEqual(prevRowData, newRowData)) {
          return prevRowData;
        }
        return newRowData;
      });
    };

    const handleSelect = useCallback((event: RowSelectedEvent) => {
      onRowSelected?.(event.data);
    }, []);

    useImperativeHandle(ref, () => ({
      updateColumnDefs,
      updateRowData,
      getTableData,
      exportAsCSV,
      stopEditingCell,
      updateCellValues,
      getLastOccupiedColumn,
      getColumnIndex,
      getColumnAtIndex,
      setRowUpdate,
      getLastOccupiedRow,
      setSideBarVisible,
      setSideBar,
      openToolPanel,
    }));

    const setRowUpdate = (rowIndex: number, data: CustomMagicTableRow[]) => {
      const row = agGridRef.current?.api.getDisplayedRowAtIndex(rowIndex);
      if (row) {
        row.setData(data);
      }
    };

    const autoSizeStrategy = useMemo<
      | SizeColumnsToFitGridStrategy
      | SizeColumnsToFitProvidedWidthStrategy
      | SizeColumnsToContentStrategy
    >(() => {
      return {
        type: 'fitGridWidth',
        defaultMinWidth: 100,
      };
    }, []);

    const getCellValue = (event: CellEditingStartedEvent | CellEditingStoppedEvent) => {
      return event.api.getCellValue({ colKey: event.column, rowNode: event.node });
    };

    const onCellEditingStarted = useCallback((event: CellEditingStartedEvent) => {
      const cellValue = getCellValue(event);
      cellEditingStart?.({
        value: cellValue,
        columnId: event.column.getId(),
        rowId: event.rowIndex !== null ? event.rowIndex + 1 : null,
      });
    }, []);

    const onCellEditingStopped = useCallback((event: CellEditingStoppedEvent) => {
      const cellValue = getCellValue(event);
      cellEditingEnd?.({
        value: cellValue,
        columnId: event.column.getId(),
        rowId: event.rowIndex !== null ? event.rowIndex + 1 : null,
      });
    }, []);

    // Get the table data in the JSON format
    const getTableData = (): MagicTableResult[] => {
      const rowCount = agGridRef.current?.api.getDisplayedRowCount() || 0;

      return Array.from({ length: rowCount }, (_, i) => {
        const row = agGridRef.current?.api.getDisplayedRowAtIndex(i)?.data || {};
        const { id, ...rest } = row;

        if (Object.keys(rest).length > 0 && id !== undefined) {
          const rowData = Object.entries(rest).map(([columnId, value]) => ({ columnId, value }));
          return { position: id, rowData };
        }
        return null;
      }).filter(Boolean) as MagicTableResult[];
    };

    // Get the column index of the given column name
    const getColumnIndex = (columnId: string) => {
      const columnDefs = agGridRef.current?.api?.getColumnDefs() || [];
      const index = columnDefs.findIndex((col) => col.headerName === columnId);
      return index - 1;
    };

    // Get the column name at the given index
    const getColumnAtIndex = (index: number) => {
      const columnDefs = agGridRef.current?.api?.getColumnDefs() || [];
      return columnDefs[index + 1]?.headerName || '';
    };

    const exportAsCSV = (fileName: string) => {
      agGridRef.current?.api.exportDataAsExcel({
        fileName: fileName,
        skipColumnHeaders: true,
        shouldRowBeSkipped: (params) => {
          const countEmptyStrings = lodash.filter(
            Object.values(params.node.data),
            (value) => value === '',
          ).length;
          return countEmptyStrings === TOTAL_COLUMNS - 1;
        },
        columnKeys: agGridRef.current?.api
          ?.getColumnDefs()
          ?.slice(1)
          ?.map((col) => (col as ColDef).field)
          ?.filter((field): field is string => field !== undefined),
      });
    };

    const getRowId = useCallback(
      (params: GetRowIdParams) => String((params.data as { id: string }).id),
      [],
    );

    const onCellEditRequest = useCallback((event: CellEditRequestEvent) => {
      const oldData = event.data;
      const field = event.colDef.field;
      const newValue = event.newValue;
      const newData = { ...oldData };
      newData[field!] = event.newValue;

      if (newData[field!] === oldData[field!] || !newValue) return;

      const rowIndex = event.node.sourceRowIndex;
      const columnId = event.colDef.field!;

      const tx = {
        update: [newData],
      };

      event.api.applyTransaction(tx);

      handleCellEditRequest({ rowIndex, columnId, newValue, event });
    }, []);

    const updateCellValues = useCallback(
      (rowNode: number, columnNode: string, newValue: string) => {
        const row = agGridRef.current?.api.getDisplayedRowAtIndex(rowNode);

        if (row) {
          const contentHeight = calculateTextHeight(newValue);
          if ((row?.rowHeight ?? 30) < contentHeight) {
            row.setRowHeight(contentHeight);
          }
          row.setDataValue(columnNode, newValue);
        }
      },
      [],
    );

    useEffect(() => {
      if (agGridRef.current?.api){
        agGridRef.current.api.resetRowHeights();
      }
    }, [rowData]);

    const stopEditingCell = () => {
      agGridRef.current?.api.stopEditing(true);
    };

    const getLastOccupiedColumn = () => {
      const lastOccupiedColumnIndexes: number[] = [];

      const columnDefs = agGridRef?.current?.api.getColumnDefs() || [];

      // Loop through each row to find the last non-empty column
      agGridRef?.current?.api.forEachNode((node) => {
        const rowData = node.data;
        let lastColumnIndex = -1;

        // Loop through each column in the row data
        columnDefs.forEach((colDef, index) => {
          const value = rowData[colDef.headerName as string];
          if (value !== null && value !== undefined && value !== '') {
            lastColumnIndex = index;
          }
        });

        lastOccupiedColumnIndexes.push(lastColumnIndex);
      });

      // Return the max last occupied index across all rows
      return Math.max(...lastOccupiedColumnIndexes);
    };

    const getLastOccupiedRow = () => {
      let lastEmptyRow = '1';
      let found = false;
      agGridRef.current?.api.forEachNode((node) => {
        const newNode = { ...node.data };

        // remove default row values
        delete newNode.id;
        delete newNode.rowHeight;

        if (Object.values(newNode).every((value) => !value)) {
          if (!found) {
            found = true;
            lastEmptyRow = node.data.id;
          }
        }
      });
      return Number(lastEmptyRow) - 1;
    };

    // Sidebar
    const setSideBarVisible = (value?: boolean) => {
      if (!value) {
        agGridRef.current!.api.setSideBarVisible(agGridRef.current!.api.isSideBarVisible());
        return;
      }
      agGridRef.current!.api.setSideBarVisible(value);
    };

    const setSideBar = (def: SideBarDef | string | string[] | boolean) => {
      agGridRef.current!.api.setGridOption('sideBar', def);
    };

    const openToolPanel = (id: string) => {
      agGridRef.current!.api.openToolPanel(id);
    };

    const getRowHeight = useCallback((params: RowHeightParams): number | undefined | null => {
      return params.data?.rowHeight ? Number(params.data.rowHeight) : 40;
    }, []);

    const getRowClass = useCallback((params: RowClassParams) => {
      const rowIndex = params?.node?.rowIndex || 0;
      const isEvenRow = rowIndex % 2 === 0;
      if (rowIndex !== 0) return isEvenRow ? 'ag-row-striped' : '';
      const data = params.node.data;
      const firstColumnHasData =
        rowIndex === 0 && typeof data === 'object' && data !== null && 'A' in data;
      return firstColumnHasData ? 'ag-row-header' : '';
    }, []);

    const components = useMemo(
      () => ({
        customCellRenderer: CustomCellRenderer,
      }),
      [CustomCellRenderer],
    );

    const rowSelection = useMemo(
      () => ({
        mode: 'multiRow' as const,
        checkboxes: false,
        headerCheckbox: false,
        enableClickSelection: true,
      }),
      [],
    );

    const onColumnHeaderContextMenu = useCallback(() => null, []);

    const memoizedColDefs = useMemo(() => [idColumn, ...colDefs], [colDefs]);

    return (
      <div className={`ag-theme-quartz ag-theme-unique ${className}`}>
        <AgGridReact
          ref={agGridRef}
          debug={false}
          loading={loading}
          rowData={rowData}
          components={components}
          columnDefs={memoizedColDefs}
          icons={{
            'cell-history': renderToString(<IconHistory />),
          }}
          defaultColDef={defaultColDef}
          getRowId={getRowId}
          getRowHeight={getRowHeight}
          autoSizeStrategy={autoSizeStrategy}
          rowSelection={rowSelection}
          onColumnHeaderContextMenu={onColumnHeaderContextMenu}
          onRowSelected={handleSelect}
          readOnlyEdit
          onCellEditingStarted={onCellEditingStarted}
          onCellEditingStopped={onCellEditingStopped}
          getContextMenuItems={contextMenuItems}
          onCellEditRequest={onCellEditRequest}
          onGridReady={onGridReady}
          sideBar={sidebar}
          onCellContextMenu={onCellContextMenu}
          getRowClass={getRowClass}
        />
      </div>
    );
  },
);

export const MagicTable = memo(Table);
