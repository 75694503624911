import { FC } from 'react';
import { IconProps } from '.';

export const IconHistory: FC<IconProps> = ({ width = '16px', height = '14px' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.14286 0C5.35619 0 2.28571 3.13444 2.28571 7H0L2.96381 10.0256L3.01714 10.1344L6.09524 7H3.80952C3.80952 3.99 6.19429 1.55556 9.14286 1.55556C12.0914 1.55556 14.4762 3.99 14.4762 7C14.4762 10.01 12.0914 12.4444 9.14286 12.4444C7.67238 12.4444 6.33905 11.83 5.37905 10.8422L4.29714 11.9467C5.53905 13.2144 7.24571 14 9.14286 14C12.9295 14 16 10.8656 16 7C16 3.13444 12.9295 0 9.14286 0ZM8.38095 3.88889V7.77778L11.6419 9.75333L12.1905 8.81222L9.52381 7.19444V3.88889H8.38095Z"
        fill="currentColor"
      />
    </svg>
  );
};
