import {
  IconWordFile,
  IconPptFile,
  IconPdfFile,
  IconHtmlFile,
  IconMdFile,
  IconTextFile,
  IconCSVFile,
  IconExcelFile,
  IconJpgFile,
  IconPngFile,
  IconExternalFile,
} from '@unique/icons';
import { FileType, getFileTypeByMimeType } from '@unique/shared-library';
import { FC, ReactNode } from 'react';

type FileTypeIconProps = {
  mimeType?: string;
  fileType?: string;
  isExternal?: boolean;
  dimensions?: string;
};

export const FileTypeIcon: FC<FileTypeIconProps> = ({
  fileType,
  isExternal = false,
  mimeType,
  dimensions,
}): ReactNode => {
  if (mimeType && !fileType) {
    fileType = getFileTypeByMimeType(mimeType);
  }
  const dimensionProps = dimensions ? { width: dimensions, height: dimensions } : {};
  switch (fileType) {
    case FileType.WORD:
    case FileType.DOC:
    case FileType.DOCX:
      return <IconWordFile {...dimensionProps} />;
    case FileType.PPT:
    case FileType.PPTX:
      return <IconPptFile {...dimensionProps} />;
    case FileType.PDF:
      return <IconPdfFile {...dimensionProps} />;
    case FileType.HTML:
    case FileType.HTM:
      return <IconHtmlFile {...dimensionProps} />;
    case FileType.MD:
      return <IconMdFile {...dimensionProps} />;
    case FileType.TXT:
      return <IconTextFile {...dimensionProps} />;
    case FileType.CSV:
      return <IconCSVFile {...dimensionProps} />;
    case FileType.XLSX:
    case FileType.XLS:
      return <IconExcelFile {...dimensionProps} />;
    case FileType.JPG:
    case FileType.JPEG:
      return <IconJpgFile {...dimensionProps} />;
    case FileType.PNG:
      return <IconPngFile {...dimensionProps} />;
    default:
      return isExternal ? <IconExternalFile /> : <IconTextFile />;
  }
};
