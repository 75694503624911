'use client';
import { IconCaret, IconFolderCloud, IconFolderUnique } from '@unique/icons';
import {
  ColumnItemType,
  FolderPathSelectionContext,
  FolderPathSelectionContextType,
} from '@unique/shared-library';
import cn from 'classnames';
import { FC, FormEvent, useContext, useEffect, useState } from 'react';
import { ColumnItemSkeleton } from './ColumnItemSkeleton';
import { uniq } from 'lodash';
import IndeterminateCheckbox from './IndeterminateCheckbox';
import { FileTypeIcon } from './FileTypeIcon';

export type ColumnItemProps = {
  label: string;
  additionalLabel?: string;
  mimeType?: string;
  href?: string;
  columnItemId?: string;
  hasCaret?: boolean;
  ownerId?: string;
  isSelected?: boolean;
  isLoading?: boolean;
  type: ColumnItemType;
  handleClick?: (href?: string) => void;
};

export const ColumnItem: FC<ColumnItemProps> = ({
  label,
  additionalLabel,
  mimeType,
  href,
  columnItemId,
  hasCaret = false,
  isSelected = false,
  isLoading = false,
  ownerId,
  type = ColumnItemType.File,
  handleClick,
}) => {
  const context = useContext(FolderPathSelectionContext) as FolderPathSelectionContextType;

  const hasSelectedMaxItems =
    context?.maxItemsToSelect && context?.selectedFileIds.length >= context?.maxItemsToSelect;

  const [isChecked, setIsChecked] = useState(
    columnItemId
      ? context?.selectedFileIds.includes(columnItemId) ||
          context?.selectedFolderIds.includes(columnItemId)
      : false,
  );

  useEffect(() => {
    if (!context?.isSelectable) return;
    if (type === ColumnItemType.Folder || type === ColumnItemType.SyncedFolder) return;
    if (ownerId && ownerId === context?.folderIdToRemove) {
      setIsChecked(false);
      context?.setSelectedFileIds((prev) => prev.filter((id) => id !== columnItemId));
      context?.setFolderIdToRemove('');
    }

    if (ownerId && ownerId === context?.folderIdToAdd) {
      setIsChecked(true);
      context?.setSelectedFileIds((prev) => uniq([...prev, columnItemId!]));
      context?.setFolderIdToAdd('');
    }
  }, [
    context?.setSelectedFileIds,
    context?.folderIdToRemove,
    context?.setFolderIdToRemove,
    context?.folderIdToAdd,
    context?.setFolderIdToAdd,
    context?.isSelectable,
  ]);

  const handleCheckboxClick = (e: FormEvent<HTMLInputElement>) => {
    if (!context?.isSelectable) return;
    e.preventDefault();
    const checkedValue = (e.target as HTMLInputElement).checked;

    // Prevent selecting multiple items if a folder is Checked
    if (
      context?.maxItemsToSelect &&
      context?.maxItemsToSelect < Number.MAX_SAFE_INTEGER &&
      type === ColumnItemType.Folder
    )
      return;

    // Prevent selecting more than max items
    if (hasSelectedMaxItems && checkedValue) return;

    setIsChecked((prev) => !prev);

    const itemAlreadyChecked =
      columnItemId &&
      (context?.selectedFileIds.includes(columnItemId) ||
        context?.selectedFolderIds.includes(columnItemId));

    if (itemAlreadyChecked) {
      if (type === ColumnItemType.File) {
        context?.setSelectedFileIds((prev) => prev.filter((id) => id !== columnItemId));
      } else {
        context?.setSelectedFolderIds((prev) => prev.filter((id) => id !== columnItemId));
      }
    } else {
      if (type === ColumnItemType.File) {
        context?.setSelectedFileIds((prev) => [...prev, columnItemId!]);
      } else {
        context?.setSelectedFolderIds((prev) => [...prev, columnItemId!]);
      }
    }

    if (type === ColumnItemType.File) return;
    if (checkedValue) {
      context?.setFolderIdToAdd(columnItemId!);
    } else {
      context?.setFolderIdToRemove(columnItemId!);
    }
  };

  return (
    <>
      {isLoading ? (
        <ColumnItemSkeleton hasCaret={hasCaret} />
      ) : (
        <button
          onClick={() => handleClick && handleClick(href)}
          className={cn({
            'flex w-[100%] cursor-pointer flex-row items-center gap-[16px] py-[12px] pl-[24px] pr-[16px] transition':
              true,
            'hover:bg-background': typeof handleClick === 'function',
            '!cursor-default': typeof handleClick !== 'function',
            'bg-background-variant': !!isSelected,
            'pl-[16px]': context?.isSelectable,
          })}
        >
          {context?.isSelectable && (
            <IndeterminateCheckbox onChange={handleCheckboxClick} checked={isChecked} />
          )}
          <div>
            {type === ColumnItemType.Folder && <IconFolderUnique />}
            {type === ColumnItemType.SyncedFolder && <IconFolderCloud />}
            {type === ColumnItemType.File && (
              <FileTypeIcon dimensions="24px" mimeType={mimeType ?? ''} />
            )}
          </div>
          <div className="flex flex-1 flex-col items-start truncate">
            <p className="text-on-background-main flex-2 w-full grow overflow-hidden truncate text-left text-sm">
              {label}
            </p>
            {!!additionalLabel && (
              <p className="text-on-background-dimmed text-xs font-normal italic">
                {additionalLabel}
              </p>
            )}
          </div>

          {!!hasCaret && <div className="text-on-background-dimmed pr-[8px]">{<IconCaret />}</div>}
        </button>
      )}
    </>
  );
};
